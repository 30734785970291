<template>
  <div>
  <modal
    name="saveFilter"
    height="180px"
    width="380px"
    styles="border-radius: 10px 10px 10px 10px; top:100px"
  >
    <div class="p-4 rounded-xl" :class="bookmarkExits ? 'mb-2' : ''">
      <!-- <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor> -->

      <div class="flex">
        <label class="text-blue-600">Save Filter</label>
        <div
          class="ml-auto text-gray-800 rounded-full border-none cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="12"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </div>
      </div>
      <div class="text-sm mb-1 text-gray-600 required">
        Filter Name<span class="text-red-500">*</span>
      </div>
      <input
        type="text"
        v-model="filterName"
        class="w-full outline-none h-9 px-2 rounded border border-solid"
      />

      <div class="error text-xs text-red-500" v-if="bookmarkExits">
        The name you have entered already exists in the system. Please choose a
        different name for the bookmark.
      </div>
      <div
        class="flex justify-items-center gap-3"
        :class="bookmarkExits ? 'mt-1' : 'mt-4'"
      >
        <button
          class="inline-flex outline-none items-center justify-center px-4 py-1 text-xs w-44 font-medium leading-6 whitespace-no-wrap bg-gray-50 border border-solid rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:shadow-none"
          :class="
            filterName !== '' && bookmarkId != null
              ? 'cursor-pointer text-blue-600 border-blue-400'
              : ''
          "
          :disabled="bookmarkId!= null && filterName !== ''? false : true"
         
           @click="handleEmailFrequency"
        >
          Set Email Frequency
        </button>
        <span
          v-if="bookmarkId === null"
          class="inline-flex outline-none items-center justify-center px-4 py-2 w-40 text-xs font-medium leading-6 whitespace-no-wrap border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
          @click="saveFrequencyFilter"
          :class="
            filterName.trim() !== ''
              ? 'bg-blue-600 cursor-pointer border-solid text-white  border-blue-700 hover:bg-blue-700 focus:ring-blue-500'
              : 'border-gray-200 bg-gray-200 text-gray-500'
          "
          >Save</span
        >
        <span
          v-else
          class="inline-flex outline-none items-center justify-center px-4 py-2 w-40 text-xs font-medium leading-6 whitespace-no-wrap border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
          @click="updateFrequencyFilter"
          :class="
            filterName.trim() !== ''
              ? 'bg-blue-600 cursor-pointer border-solid text-white  border-blue-700 hover:bg-blue-700 focus:ring-blue-500'
              : 'border-gray-200 bg-gray-200 text-gray-500'
          "
          >Update</span
        >
      </div>
    </div>
  </modal>

  <modal name="email_frequency" height="auto" width="60%">
            <div class="content-center" v-if="loadingSchedule">
                <neo-loader :loading="true" height="50px" width="50px" />
            </div>
            <div v-show="!loadingSchedule" class="flex flex-col">
                <header class="flex bg-gray-50 border-b border-0 flex-shrink-0 border-solid border-gray-200 items-center p-3 justify-between">
                    <div>Email Frequency</div>

                    <div  class="close cursor-pointer text-gray-600 hover:text-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
                            <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
                        </svg>
                    </div>
                </header>
                <div class="w-full p-2">
                    <vue-multiselect v-model="emailFreqEmails" class="input" tag-placeholder="" placeholder="Add emails" label="name" track-by="name" :options="emailOptions" :multiple="true" :checkboxes="true" :preserve-search="false" :taggable="true" :close-on-select="false" :clear-on-select="false" group-values="categories" group-label="group" :show-labels="true" :searchable="true" @tag="addTag">
                        <!-- <template slot="tag" slot-scope="props">
                            <span class="rounded-full inline-block mr-2 text-xs p-1 mt-1 mb-2 bg-gray-100">
                                {{ props.option.name }}
                                <button @click="deleteSearch(props.option.name)" class="p-0.5 cursor-pointer" style="all: unset">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 -5 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x">
                                        <line x1="18" y1="6" x2="6" y2="18" />
                                        <line x1="6" y1="6" x2="18" y2="18" />
                                    </svg>
                                </button>
                            </span>
                        </template> -->
                        <!--  -->
                    </vue-multiselect>
                </div>

                <div slot="body" class="grid gap-5 h-80 p-5">
                    <div class="flex justify-between items-center w-3/5 pr-6">
                        <label class="text-base justify-center">Type</label>
                        <select  v-model="selectedRecurrence" id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-1/4 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            <option selected>Hourly</option>
                            <option value="Daily">Daily</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                        </select>
                    </div>

                    <div class="flex justify-between items-center">
                        <label class="text-base justify-center w-24">Recurs</label>
                        <div v-if="selectedRecurrence == 'Hourly'" class="w-3/5 pl-4">
                            <span class="mr-6">Every</span>
                            <div class="timepicker timepicker1" dir="ltr">
                                <input type="number" v-model="recurrenceFields[selectedRecurrence].freqHour" class="hh N" min="0" max="23" placeholder="hh" maxlength="2" @input="validateHours" />:
                                <input type="number" v-model="recurrenceFields[selectedRecurrence].freqMinute" class="mm N" min="0" max="59" placeholder="mm" maxlength="2" />
                            </div>
                            HH:MM
                        </div>
                        <div v-if="selectedRecurrence == 'Weekly'" class="w-3/5 pl-4">
                            <div class="flex gap-2">
                                <label>Every</label>
                                <span class="input-wrapper">
                                    <button id="decrement" :disabled="recurrenceFields[selectedRecurrence].WeeklySchedule.RecurrenceWeeks <= 0" @click="recurrenceFields[selectedRecurrence].WeeklySchedule.RecurrenceWeeks = recurrenceFields[selectedRecurrence].WeeklySchedule.RecurrenceWeeks - 1">-</button>
                                    <input type="number" v-model="recurrenceFields[selectedRecurrence].WeeklySchedule.RecurrenceWeeks" id="quantity" />
                                    <button id="increment" @click="recurrenceFields[selectedRecurrence].WeeklySchedule.RecurrenceWeeks = recurrenceFields[selectedRecurrence].WeeklySchedule.RecurrenceWeeks + 1">+</button>
                                </span>
                                <label>week(s)</label>
                            </div>
                            <div class="flex gap-2 mt-2 flex-wrap">
                                <div v-for="day in dailyScheduleData" :key="day" class="flex gap-2">
                                    <input type="checkbox" @input="handledaysofweek(day)" :id="day.id" :checked="day.checked" />
                                    <label>{{ day.dayName }}</label>
                                </div>
                            </div>
                        </div>
                        <div v-if="selectedRecurrence == 'Daily'" class="flex w-3/5 pl-4">
                            <div class="flex gap-2">
                                <label>Every</label>
                                <span class="input-wrapper">
                                    <button id="decrement" :disabled="recurrenceFields[selectedRecurrence].DailySchedule.EveryNdays <= 0 && recurrenceFields[selectedRecurrence].DailySchedule.EveryWeekday" @click="recurrenceFields[selectedRecurrence].DailySchedule.EveryNdays = recurrenceFields[selectedRecurrence].DailySchedule.EveryNdays - 1">-</button>
                                    <input type="number" :disabled="recurrenceFields[selectedRecurrence].DailySchedule.EveryWeekday" v-model="recurrenceFields[selectedRecurrence].DailySchedule.EveryNdays" value="1" id="quantity" />
                                    <button id="increment" :disabled="recurrenceFields[selectedRecurrence].DailySchedule.EveryWeekday" @click="recurrenceFields[selectedRecurrence].DailySchedule.EveryNdays = recurrenceFields[selectedRecurrence].DailySchedule.EveryNdays + 1">+</button>
                                </span>
                                <label>day(s)</label>
                            </div>

                            <div class="flex gap-2 ml-6">
                                <input type="radio" @click="handleCheckbox('everyweekday')" :checked="recurrenceFields[selectedRecurrence].DailySchedule.EveryWeekday" id="everyweekday" :disabled="recurrenceFields[selectedRecurrence].DailySchedule.EveryNdays > 0" />
                                <label>Every weekday</label>
                            </div>
                        </div>
                        <div v-if="selectedRecurrence == 'Monthly'" class="flex w-3/5 p-4">
                            <div class="flex gap-2">
                                <label>Day</label>
                                <span class="input-wrapper">
                                    <button id="decrement" :disabled="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.DayInterval <= 0" @click="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.DayInterval = recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.DayInterval - 1">-</button>
                                    <input type="number" v-model="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.DayInterval" value="1" id="quantity" />
                                    <button id="increment" @click="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.DayInterval = recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.DayInterval + 1">+</button>
                                </span>
                                <label>of every</label>
                                <span class="input-wrapper">
                                    <button id="decrement" :disabled="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.MonthInterval <= 0" @click="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.MonthInterval = recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.MonthInterval - 1">-</button>
                                    <input type="number" v-model="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.MonthInterval" value="1" id="quantity" />
                                    <button id="increment" @click="recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.MonthInterval = recurrenceFields[selectedRecurrence].MonthlySchedule.DayRecurrence.MonthInterval + 1">+</button>
                                </span>
                                <label>months(s)</label>
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-between items-center">
                        <label>Starts on</label>
                        <div style="z-index:10009;" class="w-3/5 pl-4">
                            <neo-datepicker class="h-10" style="width: 60% !important" placeholder="Select Start Date" v-model="freqStartDate" value-type="format" format="YYYY-MM-DD" ></neo-datepicker>
                        </div>
                    </div>
                    <div class="flex justify-between items-center">
                        <label>Ends</label>

                        <div class="flex gap-3 p-3 w-3/5 pr-4">
                            <input type="radio" id="never_radio" :checked="frequencyEnds.never" @click="handleFreqEndType" />
                            <label>Never</label>

                            <input id="after_radio" type="radio" :checked="frequencyEnds.after" @click="handleFreqEndType" />
                            <label>After</label>
                            <span class="input-wrapper">
                                <button id="decrement" @click="handleEndOccurence">-</button>
                                <input type="number" :min="1" v-model="frequencyEnds.occurence" id="quantity" />
                                <button id="increment" @click="frequencyEnds.occurence = frequencyEnds.occurence + 1">+</button>
                            </span>
                            <label>Occurencies</label>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
            <footer v-if="!loadingSchedule" class="bg-gray-50 flex-shrink-0 flex border-t border-0 border-solid border-gray-200 p-3 justify-end mb-2 h-full mt-8 relative">
                <button @click="setEmailScheduler" label="Submit" class="w-40 rounded-lg h-10 text-white bg-brand items-center cursor-pointer border-0 text-center">
                    Submit
                </button>
            </footer>
        </modal>

        <modal name="delete-filter" width="380px" height="auto" styles="border-radius: 10px 10px 10px 10px">
        <div class="mt-5 flex justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="orange" class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z" />
            </svg>
        </div>
        <div class="flex justify-center mt-5 px-3 font-medium text-base"><p>You are deleting "{{filterName}}"</p></div>
        <div class="flex justify-center mt-5 text-xs px-5 text-gray-500" style="text-align: center; line-height: 1.6">
            This will delete your filter permanently and you cannot get it back.
        </div>
        <div class="flex justify-center mt-5 mb-6">
            <span class="inline-flex mr-4 outline-none cursor-pointer w-28 items-center justify-center px-4 py-2 text-base font-medium leading-6 text-blue-600 whitespace-no-wrap bg-gray-50 border border-solid border-blue-400 rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:shadow-none" @click="hideDeleteFilter()">
                Cancel
            </span>
            <span @click="deleteBookmark()" class="inline-flex cursor-pointer outline-none items-center w-28 justify-center px-4 py-2 text-base font-medium leading-6 text-white whitespace-no-wrap bg-blue-600 border-solid border border-blue-700 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Delete
            </span>
        </div>
    </modal>
  </div>
  

</template>

<script>
import { EventBus } from "@/main.js";
import dashboardService from "../services.js";
import Loader from "@shared/loader";
import VueMultiselect from "@shared/saas-components/vue-multiselect/index";
import DatePicker from "vue2-datepicker";
export default {
  name: "emailFrequency",
  components: {
      "neo-loader":Loader,
      VueMultiselect,
      "neo-datepicker": DatePicker
  },
  data() {
    return {
      bookmarkId: null,
      filterName: "",
      bookmarkExits: false,
      viewData: null,
      saveFilter: false,
      frequencyEnds: {
        never: true,
        after: false,
        occurence: 1,
      },
      recurrenceFields: {
        Hourly: {
          freqMinute: 15,
          freqHour: 0,
          HourlySchedule: {
            ScheduleInterval: null,
          },
        },
        Daily: {
          DailySchedule: {
            RecurrenceType: null,
            EveryNdays: 0,
            EveryWeekday: false,
          },
        },
        Weekly: {
          WeeklySchedule: {
            RecurrenceWeeks: 0,
            RecurrenceDays: [],
          },
        },
        Monthly: {
          MonthlySchedule: {
            RecurrenceType: null,
            DayRecurrence: {
              DayInterval: 0,
              MonthInterval: 0,
            },
          },
        },
      },
      selectedView: null,
      selectedRecurrence: "Hourly",
      customBreadCrumbs: [],
      boldBiInstance: null,
      toggleViews: false,
      dashboardInstance: null,
      ans: false,
      savedViews: [],
      emailFreqEmails: [],
      savedEmails: [],
      emailOptions: [{ group: "emails", categories: [] }],
      freqStartDate: new Date().toISOString().slice(0, -5) + "Z",
      dailyScheduleData: [
        { id: 1, dayName: "Monday", checked: false },
        { id: 2, dayName: "Tuesday", checked: false },
        { id: 3, dayName: "Wednesday", checked: false },
        { id: 4, dayName: "Thursday", checked: false },
        { id: 5, dayName: "Friday", checked: false },
        { id: 6, dayName: "Saturday", checked: false },
        { id: 7, dayName: "Sunday", checked: false },
      ],
      loadingSchedule: true,
    };
  },
  async mounted() {
    EventBus.$on("saveDashboardView", async (data) => {
        this.showFrequencyFilter(data);
    });
    EventBus.$on("EditDashboardView", async (data) => {
        this.editBookmark(data, true);
    });
    EventBus.$on("DeleteDashboardView", async (data) => {
        this.deleteFrequencyFilter(data);
    });
    EventBus.$on("showEmailFreq", async (data) => {
        this.editBookmark(data)
        await this.handleEmailFrequency(data);
    });
  },
  methods: {
      deleteFrequencyFilter(freq) {
            this.$modal.show("delete-filter");
            this.filterName = freq.name;
            this.bookmarkId = freq.view_id;
            this.scheduleId = freq?.schedule_id;
    },
     async deleteBookmark() {
            let  payload  = {
                bookmarkId: this.bookmarkId
            }
            if (this.scheduleId) {
                payload.scheduleId = this.scheduleId
            }
            let response = await dashboardService.deleteBookmark(payload)
            if (response && response.data) {
                this.$toast.success(response.data.message);
                this.$modal.hide("delete-filter");
                this.bookmarkId = null;
                this.filterName = "";
                this.$emit('refreshBookmark')
            }
        },
      async updateFrequencyFilter() {
            if (this.filterName !== "") {
                let payload = {
                    name: this.filterName,
                    dashboard_id: this.$route.params.id,
                };
                dashboardService.updateDashboardBookmarks(this.bookmarkId, payload).then(()=>{
                    this.$emit('refreshBookmark')
                    this.$toast.success("Bookmark updated successfully.");
                }).catch((err)=>{
                    this.$toast.error(err.response.data.detail.message);
                })
            }
        },
       editBookmark(view, schedule) {
            this.selectedView = view
            this.filterName = view.name;
            this.saveFilter = true;
            this.newFilter = false;
            this.bookmarkExits = false;
            this.bookmarkId = view.view_id;
            this.scheduleId = view?.schedule_id;
            this.filters = view.filters;
            if (schedule) {
            this.$modal.show("saveFilter");
            }
    },
    showFrequencyFilter(data) {
      this.viewData = data;
      this.$modal.show("saveFilter");
      this.saveFilter = true;
      this.filterName = "";
      this.bookmarkId = null;
      this.bookmarkExits = false;
    },
    saveFrequencyFilter() {
      this.newFilter = true;

      if (this.filterName.trim() !== "") {
        this.viewData.name = this.filterName;
        this.viewData.dashboard_id = this.$route.params.id;
        this.viewData.company = this.$store.getters.getAccountId;
        dashboardService
          .saveDashboardViews(this.viewData)
          .then(async (res) => {
            this.bookmarkId = res.bookmark_id;
            this.selectedView = {view_id: this.bookmarkId, name: this.viewData.name, filters: this.viewData.filters}
            this.$emit('refreshBookmark')
            this.$toast.success("Bookmark added successfully.");
            this.filters = this.viewData.filters;
          })
          .catch((err) => {
              console.log(err)
            this.$toast.error(err.response.data.detail.message);
          });
      }
    },
    showEmailFrequency() {
        this.$modal.show("email_frequency");
        this.showEmailFrequency()
    },
    handleFreqEndType() {
            this.frequencyEnds.never = !this.frequencyEnds.never;
            this.frequencyEnds.after = !this.frequencyEnds.after;
        },
        handleEndOccurence() {
            if (this.frequencyEnds.occurence <= 1) {
                this.frequencyEnds.occurence = 1;
            } else {
                this.frequencyEnds.occurence = this.frequencyEnds.occurence - 1;
            }
        },
        handledaysofweek(checkedDay) {
            // this.weekDaysSelected = false;
            this.dailyScheduleData.find((el) => {
                if (el.id === checkedDay.id) {
                    let check = !this.dailyScheduleData.includes(el.label);
                    if (check) {
                        el.checked = true;
                        this.recurrenceFields.Weekly.WeeklySchedule.RecurrenceDays.push(el.dayName);
                    } else {
                        const index = this.recurrenceFields.Weekly.WeeklySchedule.RecurrenceDays.indexOf(el.label);
                        if (index > -1) {
                            this.recurrenceFields.Weekly.WeeklySchedule.RecurrenceDays.splice(index, 1);
                            this.dailyScheduleData.find((el) => {
                                if (el.id === checkedDay.id) {
                                    el.checked = false;
                                }
                            });
                        }
                    }
                }
            });
        },
        handleCheckbox(id) {
            const day = document.getElementById(id);
            if (id === "everyweekday" && day.checked) {
                if (this.recurrenceFields.Daily.DailySchedule.EveryWeekday) this.recurrenceFields.Daily.DailySchedule.EveryWeekday = false;
                else this.recurrenceFields.Daily.DailySchedule.EveryWeekday = true;
            }
        },
        validateScheduler() {
            let message = "";
            if (this.emailFreqEmails.length == 0) {
                message = "Please enter email.";
            }
            if (this.recurrenceFields.Hourly.freqMinute <= 0 || this.recurrenceFields.Hourly.freqHour < 0 || this.recurrenceFields.Hourly.freqHour > 23 || this.recurrenceFields.Hourly.freqMinute > 59) {
                message = "Please enter minimum 15 minutes.";
            }
            console.log(message)
        },
        async setEmailScheduler() {
            if (parseInt(this.recurrenceFields.Hourly.freqMinute) < 15) {
                this.$toast.error("Minimum time interval is 15 mins.");
                return;
            }
            if (this.emailFreqEmails.length != 0) {
                try {
                    let payload = {
                        name: this.selectedView.name,
                        item_id: this.$route.params.id,
                        start_time: new Date(this.freqStartDate).toISOString().slice(0, -5) + "Z",
                        never_end: this.frequencyEnds.never,
                        end_occurence: !this.frequencyEnds.never ? this.frequencyEnds.occurence : 0,
                        email_list: this.emailFreqEmails.map((res) => res.name),
                        schedule_type: this.selectedRecurrence,
                        email_body: `/bi-dashboard-view/${this.$route.params.id}?view_id=${this.selectedView.view_id}&view_name=${this.selectedView.name}&filters=${this.selectedView.filters}`,
                    };
                    if (this.selectedRecurrence === "Hourly") {
                        this.recurrenceFields.Hourly.HourlySchedule.ScheduleInterval = this.recurrenceFields.Hourly.freqHour + ":" + this.recurrenceFields.Hourly.freqMinute;
                        payload.schedule_data = {HourlySchedule: this.recurrenceFields.Hourly.HourlySchedule};
                    }
                    if (this.selectedRecurrence === "Daily") {
                        this.recurrenceFields.Daily.DailySchedule.RecurrenceType = this.recurrenceFields.Daily.DailySchedule.EveryWeekday ? "EveryWeekday" : "EveryNdays";
                        if (this.recurrenceFields.Daily.DailySchedule.RecurrenceType === "EveryNdays") {
                            this.recurrenceFields.Daily.DailySchedule.EveryWeekday = "";
                        } else {
                            this.recurrenceFields.Daily.DailySchedule.EveryNdays = 0;
                        }
                        payload.schedule_data = this.recurrenceFields.Daily;
                    }
                    if (this.selectedRecurrence === "Weekly") {
                        payload.schedule_data = this.recurrenceFields.Weekly;
                    }
                    if (this.selectedRecurrence === "Monthly") {
                        this.recurrenceFields.Monthly.MonthlySchedule.RecurrenceType = "DayRecurrence";
                        payload.schedule_data = this.recurrenceFields.Monthly;
                    }
                    payload.view_id = this.selectedView.view_id;
                    console.log(this.selectedView)
                    if (this.selectedView.schedule_id) {
                        payload.schedule_id = this.selectedView.schedule_id;
                        let removeEmailList = [];
                        this.savedEmails.forEach((result) => {
                            if (!this.emailFreqEmails.map((res) => res.name).includes(result)) {
                                removeEmailList.push(result);
                            }
                        });
                        payload.remove_email_list = removeEmailList;
                    }
                    payload = {...payload, ...this.recurrenceFields[this.selectedRecurrence]};
                    dashboardService
                        .addDashboardSchedule(payload)
                        .then((response) => {
                            this.$emit("refreshBookmark");
                            this.$toast.success("Schedule added successfully.");
                            this.savedEmails = this.emailFreqEmails.map((res) => res.name);
                            this.selectedView.scheduleId = response.data.schedule_id;
                            this.selectedView.schedule_id = response.data.schedule_id
                        })
                        .catch((err) => {
                            this.$toast.error(err.response.data.detail.message);
                        });
                } catch (error) {
                    console.log(error);
                    this.$toast.error("Something went wrong.");
                }
            } else {
                this.$toast.error("Please enter email.");
            }
        },
        validateHours() {
            if (this.freqHour > 23) {
                this.freqHour = 23;
            }
            if (this.freqHour < 0) {
                this.freqHour = 0;
            }
            this.recurrenceFields[this.selectedRecurrence].HourlySchedule.ScheduleInterval = this.recurrenceFields[this.selectedRecurrence].freqHour.toString() + ":" + this.recurrenceFields[this.selectedRecurrence].freqMinute.toString();
        },
        deleteSearch(value) {
            let index = this.emailFreqEmails.findIndex((el) => el.name === value);
            if (index > -1) this.emailFreqEmails.splice(index, 1);
        },
        addTag(newTag) {
            let newTags = [];
            newTags = [
                ...newTags,
                ...newTag
                    ?.split(/\t/)[0]
                    ?.split("\n")
                    ?.filter((el) => el && (el !== " " || el !== "")),
            ];

            let newTagsData = newTags.filter(function(item) {
                return item.trim() != "";
            });
            newTagsData.forEach((tg) => {
                if (tg !== "" && tg !== " ") {
                    tg = tg?.trim();
                    const tag = {
                        name: tg,
                        value: tg?.trim(),
                        code: tg.substring(0, 2) + Math.floor(Math.random() * 10000000),
                    };
                    let index = this.emailFreqEmails?.findIndex((item) => item?.value === tg);
                    let secIndex = this.emailOptions[0]?.categories?.findIndex((item) => item?.value === tg);
                    if (index > -1) {
                        console.log("2")
                    } else {
                        this.emailFreqEmails.push(tag);
                    }
                    if (secIndex > -1) {
                        console.log("1")
                    } else {
                        this.emailOptions[0]?.categories.push(tag);
                    }
                }
            });
        },
        async handleEmailFrequency() {
            this.loadingSchedule = true;
            this.$modal.show("email_frequency");
            const {data} = await dashboardService.getDashboardSchedule(this.selectedView.view_id);
            const scheduleData = data.schedule;
            if (scheduleData != null) {
                this.frequencyEnds = {
                    never: scheduleData.NeverEnd,
                    after: scheduleData.NeverEnd ? false : true,
                    occurence: scheduleData.EndAfterOccurrence == 0 ? 1 : scheduleData.EndAfterOccurrence,
                };
                this.freqStartDate = scheduleData.StartDate;
                this.emailFreqEmails = [];
                scheduleData.ExternalRecipientsList.forEach((res) => {
                    this.emailFreqEmails.push({name: res, value: res});
                    this.savedEmails.push(res);
                });
                this.selectedRecurrence = scheduleData.RecurrenceType;
                this.recurrenceFields[this.selectedRecurrence] = scheduleData[this.selectedRecurrence];
                this.loadingSchedule = false;
            } else {
                this.loadingSchedule = false;
                this.emailFreqEmails = [];
                this.savedEmails = [];
                this.freqStartDate = new Date().toISOString().slice(0, -5) + "Z";
                this.frequencyEnds = {
                    never: true,
                    after: false,
                    occurence: 1,
                };
                this.selectedRecurrence = "Hourly";
            }
        },
        deleteView() {
            this.$modal.show("file_upload");
        },
        hideModal() {
            this.$modal.hide("file_upload");
        },
        hideEmailFreqModal() {
            this.$modal.hide("email_frequency");
        },
  },
  beforeDestroy() {
    EventBus.$off("saveDashboardView");
    EventBus.$off("EditDashboardView");
    EventBus.$off("DeleteDashboardView");
    EventBus.$off("showEmailFreq");
  },
};
</script>

<style scoped lang="scss">


.input {
    position: relative;
    margin-right: 0.25rem;
    user-select: none;
    font-family: var(--brand-font);
    .dropdown {
        width: inherit;
        // height: 2.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        box-sizing: border-box;
        transition: var(--transition-mnml);
        border-bottom: 1px solid transparent;
        .display {
            width: 100%;
            .label {
                text-align: left;
                margin: 0;
                color: var(--text-fade-color);
                font-size: 12px;
            }
            span {
                display: inline-flex;
                align-items: center;
                width: 100%;
                img {
                    width: 1.5rem;
                    border-radius: var(--element-border);
                }
                input {
                    outline: none;
                    border: none;
                    width: 95%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 14px;
                    background-color: transparent;
                    &::placeholder {
                        color: var(--text-fade-color);
                    }
                }
            }
        }
    }
    .dropdown-content {
        position: absolute;
        right: 1px;
        margin-top: 3px;
        width: 125px;
        background-color: #ffffff;
        z-index: 25;
        max-height: 256px;
        // overflow: scroll;
        overflow-x: hidden;
        border-radius: 0 0 var(--element-border) var(--element-border);
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: #a8b5c9;
            border-radius: 20rem;
        }
        &::-webkit-scrollbar-corner {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #9cadc6;
        }
        ul {
            list-style: none;
            width: 100%;
            margin: 0;
            padding: 0;
        }
    }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.timepicker {
    border: 1px solid rgb(42, 42, 42);
    text-align: center;
    display: inline;
    border-radius: 4px;
    padding: 2px;
}

.timepicker .hh,
.timepicker .mm {
    width: 30px;
    outline: none;
    border: none;
    text-align: center;
}

.timepicker.valid {
    border: solid 1px springgreen;
}

.timepicker.invalid {
    border: solid 1px red;
}
#list {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid rgba(218, 218, 218, 0.87);
    background: #fff;
    margin-bottom: 12px;
}

#editPage {
    width: 100%;
    height: 100%;
}
#dashboard_container {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;
}

.input-wrapper {
    width: 100px;
    height: 20px;
    display: flex;
    border-radius: 50%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    text-align: center;
}

.input-wrapper * {
    border: none;
    width: 20px;
    flex: 1;
}

.input-wrapper button {
    cursor: pointer;
}

.input-wrapper button:first-child {
    color: red;
}

.input-wrapper button:last-child {
    color: green;
}

.neo-datepicker {
    display: flex;
    justify-content: space-between;

    ::v-deep {
        select {
            border: 1px solid #ced4da;
            background-color: white;
            // height: 10px;
        }
    }
}
</style>
