<template>
  <div id="editPage">
    <email-frequency @refreshBookmark="getBookmarks"/>
    <div class="flex justify-end space-x-4">
      <dropdown 
        @handleIconClick="handleClick"
        placeholder="Saved Filters"
          :options="bookmarksList"
          :config="{ label: 'name', trackBy: 'view_id' }"
          class="w-full"
           width="392px"
          minWidth="182px"
          style="z-index:1000;"
          :colors="{
            text: '#282828',
            bg: 'white',
            svg: '#282828',
            border: '#e8e8e8',
          }"
      >
      
      </dropdown>
      <dropdown
          reposition
          searchable
          class="filter-dropdown h-10"
          placeholder="Dashboards"
           style="z-index:1000;"
          :options="dashboardOptions"
          v-model="selectedDashboard"
          :colors="{
            text: '#282828',
            bg: 'white',
            svg: '#282828',
            border: '#e8e8e8',
          }"
          :limit="1"
          width="192px"
          minWidth="102px"
          :config="{ label: 'labelName', trackBy: 'dashboard_id' }"
          @input="selectDashboard"
        />
    </div>
    <!-- <button class="my-3 px-5 py-2 text-sm text-white border-0 outline-none ml-2 bg-blue-600 hover:bg-brand-hover font-medium rounded-lg cursor-pointer  drop-shadow-sm" @click=routeBack()>Back</button> -->
    <div id="dashboard_container" class="mt-3"></div> 
  </div>
</template>

<script>
import { BoldBI } from "@boldbi/boldbi-embedded-sdk";
import BoldBiConfig from "@/boldbi/config";
import biHelper from "@/boldbi";
import VueCookies from "vue-cookies";
import dropdown from "@shared/components/dropdown-base";
import axios from "@/axios"
import emailFrequency from "./components/emailFrequency.vue"
import {EventBus} from "@/main.js";

export default {
  name: "Bi-Dashboard-View",
  data() {
    return {
      dashboardOptions:[],
      selectedDashboard: [],
      bookmarksList: []

    };
  },
  components: {
    dropdown,
    emailFrequency

  },

  computed: {
    viewId() {
      if (this?.$route?.query?.view_id) {
        return this.$route.query.view_id;
      } else {
        return null;
      }
    },
    dashboardId() {
      return this.$route.params.id;
    },
  },

  async mounted() {
    await this.getTenantDashboards();
    await this.getBookmarks()
    const dashboardId = this.$route.params.id;
    this.loadDashboardBi(dashboardId);
  },
  methods: {
   handleClick(icon, freq) {
     if (icon == 'EditIcon') {
        EventBus.$emit("EditDashboardView", freq.option);
     }
     else if (icon == 'DeleteIcon') {
       EventBus.$emit("DeleteDashboardView", freq.option);
     }
     else {
       EventBus.$emit("showEmailFreq", freq.option);
     }
   },
    handleSaveModal() {
       this.$modal.show("saveFilter");
    },
     async getBookmarks() {
            try {
                const {data} = await axios.get(`/dashboard/bookmarks/${this.selectedDashboard.tenant_dashboard_id}`);
                this.bookmarksList = data.views;
                this.bookmarksList.forEach((res)=>{
                  res["icons"] = []
                  res.icons.push("EditIcon")
                  if (res.send_email) {
                  res.icons.push("EmailGreen")
                  }
                  else {
                    res.icons.push("EmailDefault")
                  }
                  res.icons.push("DeleteIcon")
                 
                  })
               
            } catch {
                return {};
            }
    },
    loadDashboardBi(dashboardId) {
      let token = VueCookies.get("token");
      const boldBiHeader = {
        Authorization: "Bearer " + token,
      };
      let boldbiData = {
        serverUrl: BoldBiConfig.BASE_URL,
        dashboardId: dashboardId,
        embedContainerId: "dashboard_container", // This should be the container id where you want to embed the dashboard
        embedType: BoldBI.EmbedType.Component,
        environment: BoldBI.Environment.Enterprise,
        mode: BoldBI.Mode.View,
        
          height: "100%",
          width: "100%",
          authorizationServer: {
            url: biHelper.generateEmbedUrl(),
            headers: boldBiHeader,
          },
          expirationTime: "100000",
          dashboardSettings: {
            beforeSaveViewDialogOpen: function(args) {
                        args.cancel = true;
                        const payload = {
                            filters: args.data.encryptedData,
                            dashboard_id: this.dashboardId,
                            name: "",
                        };
                        EventBus.$emit("saveDashboardView", payload);
                    },
        
                    filterOverviewSettings: {
                        showSaveIcon: true,
                    },
                },
          onError: function() {
          document.getElementById("dashboard_container").style.textAlign = "center"
          document.getElementById("dashboard_container").style.marginTop = "20%"
          document.getElementById("dashboard_container").style.color = "red"
          document.getElementById("dashboard_container").innerHTML = "<h3>Error in loading dashboard, Please try again</h3>"
        },
        };
            if (this.viewId) {
                boldbiData.dashboardSettings.filterOverviewSettings = {
                    viewId: this.$route.query.view_id,
                    viewName: this.$route.query.view_name,
                    showSaveIcon: true,
                };
                boldbiData.filterParameters = this.$route.query.filters;
            }
            var boldbiEmbedInstance = BoldBI.create(boldbiData);
            boldbiEmbedInstance.loadDashboard();
    },
    selectDashboard() {
       if (this.selectedDashboard?.name != 'Default Dashboard') {
          this.$router.push({
            name: "Bi-Dashboard-View",
            params: {
              id: this.selectedDashboard.dashboard_id,
            },
          });
          const dashboardId = this.$route.params.id;
          this.loadDashboardBi(dashboardId);
        } else {
          this.$router.push({
            path: "default-dashboard",
            name:"Dashboard - Page 2",
            params: {
              id: process.env.VUE_APP_DEFAULT_DASHBOARD_KEY,
            },
          });
          
        }
    },
    async getTenantDashboards() {
      try {
        const { data } = await axios.get("/dashboard/assigned-dashboards");
        this.dashboardOptions = data.dashboards
        .map((dashboard) => {
          let bbDashboard = dashboard;
          if (dashboard.name.includes("BB_")) {
            bbDashboard.labelName = dashboard.name.split("_")[1];
          } else {
            bbDashboard.labelName = dashboard.name;
          }

          bbDashboard.name = dashboard.name;
          return bbDashboard;
        });
        if (this.$route.params.id) {
          this.selectedDashboard = this.dashboardOptions.find((res)=>res.dashboard_id==this.$route.params.id)
        }
        else {
        this.selectedDashboard = this.dashboardOptions?.[0];
        }
        if (this.selectedDashboard?.name != 'Default Dashboard') {
          this.$router.push({
            name: "Bi-Dashboard-View",
            params: {
              id: this.selectedDashboard.dashboard_id,
            },
            query: {...this.$route.query}
          });
        }
        else {
          this.$router.push({
            name: "default-dashboard"
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    routeBack() {
      this.$router.push({ name: "Dashboard - Page 2" });
    },
  },
};
</script>
<style scoped lang="scss">

#editPage {
  width: 100%;
  height: 100%;
}
</style>
